import React from 'react';
import { Carousel } from 'react-bootstrap';


const ImageSlider = () => {
    return (
        <Carousel>
            <Carousel.Item>
                <img
                    className="d-block w-100 "
                    style={{height:'500px'}}
                    src="img/WhatsApp Image 2024-07-11 at 16.35.03_b6d9679b.jpg"
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    style={{height:'500px'}}
                    src="img/WhatsApp Image 2024-07-11 at 16.34.55_50e729f9.jpg"
                    alt="Second slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    style={{height:'500px'}}
                    src="img/WhatsApp Image 2024-07-11 at 16.34.56_ceec6de0.jpg"
                    alt="Third slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    style={{height:'500px'}}
                    src="img/WhatsApp Image 2024-07-11 at 16.34.56_e6b98f16.jpg"
                    alt="Third slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    style={{height:'500px'}}
                    src="img/WhatsApp Image 2024-07-11 at 16.34.58_62ea2b59.jpg"
                    alt="Third slide"
                />
            </Carousel.Item>
        </Carousel>
    );
};

export default ImageSlider;
