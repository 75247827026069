export const Header = () => {

    const openWhatsApp = () => {
        // Replace '1234567890' with your WhatsApp number, including country code
        const whatsappNumber = '+94704998667';
        const message = encodeURIComponent('Hello, I would like to know more about...');
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${message}`;

        window.open(whatsappUrl, '_blank', 'width=600,height=600');
    };

    const handleClick = () => {
        /* scrollToTop();*/
        openWhatsApp();
    };

    return (

        <>
            <div className="container-fluid bg-dark px-0">
                <div className="row gx-0">
                    <div className="col-lg-3 bg-dark d-none d-lg-block">
                        <a href="/"
                            className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
                            <h1 className="m-0 text-primary text-uppercase" style={{ fontSize: '20px' }}>DK Zur Einkehr Hotel</h1>
                        </a>
                    </div>
                    <div className="col-lg-9">
                        <div className="row gx-0 bg-white d-none d-lg-flex">
                            <div className="col-lg-7 px-5 text-start">
                                <div className="h-100 d-inline-flex align-items-center py-2 me-4">
                                    <i className="fa fa-envelope text-primary me-2"></i>
                                    <p className="mb-0">dumindawalgamage@gmail.com</p>
                                </div>
                                <div className="h-100 d-inline-flex align-items-center py-2">
                                    <i className="fa fa-phone-alt text-primary me-2"></i>
                                    <p className="mb-0">041 444 4884
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 px-5 text-end">
                                <div className="d-inline-flex align-items-center py-2">
                                    <a className="me-3" href=""><i className="fab fa-whatsapp" onClick={handleClick}></i></a>
                                    <a className="me-3" href="https://www.instagram.com/dk_zur_einkehr_hotel_?igsh=eHR0aWJobmJ0aTZv"><i className="fab fa-instagram"></i></a>
                                    <a className="me-3" href="https://www.facebook.com/profile.php?id=100087495397443&mibextid=LQQJ4d"><i className="fab fa-facebook"></i></a>
                                </div>
                            </div>
                        </div>
                        <nav className="navbar navbar-expand-lg bg-dark navbar-dark p-3 p-lg-0">
                            <a href="/" className="navbar-brand d-block d-lg-none">
                                <h1 className="m-0 text-primary text-uppercase" style={{ fontSize: '20px' }}>DK Zur Einkehr Hotel</h1>
                            </a>
                            <button type="button" className="navbar-toggler" data-bs-toggle="collapse"
                                data-bs-target="#navbarCollapse">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                                <div className="navbar-nav mr-auto py-0">
                                    <a href="/" className="nav-item nav-link active">Home</a>
                                    <a href="about" className="nav-item nav-link">About</a>
                                    <a href="service" className="nav-item nav-link">Services</a>
                                    <a href="room" className="nav-item nav-link">Rooms</a>
                                    <a href="gallery" className="nav-item nav-link">Gallery</a>
                                    {/*<div className="nav-item dropdown">
                                        <a href="#" className="nav-link dropdown-toggle"
                                           data-bs-toggle="dropdown">Pages</a>
                                        <div className="dropdown-menu rounded-0 m-0">
                                            <a href="bookroom" className="dropdown-item">Booking</a>
                                            <a href="team.html" className="dropdown-item">Our Team</a>
                                            <a href="testimonial.html" className="dropdown-item">Testimonial</a>
                                        </div>
                                    </div>*/}
                                    <a href="contact" className="nav-item nav-link">Contact</a>
                                </div>
                                <a href="#"
                                    className="btn btn-primary rounded-0 py-4 px-md-5 d-none d-lg-block" onClick={handleClick}>Contact Us<i className="fa fa-arrow-right ms-3"></i></a>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}
