export const Footer = () => {


    const openWhatsApp = () => {
        // Replace '1234567890' with your WhatsApp number, including country code
        const whatsappNumber = '+94704998667';
        const message = encodeURIComponent('Hello, I would like to know more about...');
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${message}`;

        window.open(whatsappUrl, '_blank', 'width=600,height=600');
    };

    const handleClick = () => {
        /* scrollToTop();*/
        openWhatsApp();
    };

    return (

        <>
            <div className="container-fluid bg-dark text-light footer wow fadeIn" data-wow-delay="0.1s">
                <div className="container pb-5">
                    <div className="row g-5">
                        <div className="col-md-6 col-lg-4">
                            <div className="bg-primary rounded p-4">
                                <a href="/"><h1 className="text-white text-uppercase mb-3">DK Zur Einkehr Hotel</h1></a>
                                <p className="text-white mb-0">
                                    Book with us to have your all time favorite experience in Ahangama!
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <h6 className="section-title text-start text-primary text-uppercase mb-4">Contact</h6>
                            <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Welhengoda Road ,
                                Welhengoda, Ahangama
                            </p>
                            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>041 444 4884
                            </p>
                            <p className="mb-2"><i className="fa fa-envelope me-3"></i>dumindawalgamage@gmail.com</p>
                            <div className="d-flex pt-2">
                                <a className="btn btn-outline-light btn-social" href=""><i
                                    className="fab fa-whatsapp" onClick={handleClick}></i></a>
                                <a className="btn btn-outline-light btn-social"
                                    href="https://www.instagram.com/dk_zur_einkehr_hotel_?igsh=eHR0aWJobmJ0aTZv"><i
                                        className="fab fa-instagram"></i></a>
                                <a className="btn btn-outline-light btn-social"
                                    href="https://www.facebook.com/profile.php?id=100087495397443&mibextid=LQQJ4d"><i
                                        className="fab fa-facebook"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="row gy-5 g-4">
                                <div className="col-md-6">
                                    <h6 className="section-title text-start text-primary text-uppercase mb-4">Company</h6>
                                    <a className="btn btn-link" href="/">Home</a>
                                    <a className="btn btn-link" href="contact">Contact Us</a>
                                    <a className="btn btn-link" href="room">Rooms</a>
                                    <a className="btn btn-link" href="service">Service</a>
                                    <a className="btn btn-link" href="about">About Us</a>
                                </div>
                                <div className="col-md-6">
                                    <h6 className="section-title text-start text-primary text-uppercase mb-4">Services</h6>
                                    <p className="btn btn-link">Beach Access </p>
                                    <p className="btn btn-link">Bathroom</p>
                                    <p className="btn btn-link">Outdoors</p>
                                    <p className="btn btn-link">Kitchen </p>
                                    <p className="btn btn-link">Room Amenities </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <a className="border-bottom" href="#">DK Zur Einkehr Hotel</a>, All Right
                                Reserved.

                                Designed By <a className="border-bottom" href="https://www.loopy.lk/">Loopy Holdings</a>

                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                <div className="footer-menu">
                                    <a href="/">Home</a>
                                    <a href="">Cookies</a>
                                    <a href="">Help</a>
                                    <a href="">FQAs</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <a className="btn btn-lg btn-primary btn-lg-square back-to-top" onClick={handleClick}>
                <i className="fab fa-whatsapp"></i>
            </a>
        </>
    )
}
