import {Booking} from "../component/Booking";
import {Newsletter} from "../component/Newsletter";

export const Contact = () => {

    return(
        <>
            {/*Page Header Start */}
            <div className="container-fluid page-header mb-5 p-0" style={{backgroundImage: 'url(/img/contact.jpg)'}}>
                <div className="container-fluid page-header-inner py-5">
                    <div className="container text-center pb-5">
                        <h1 className="display-3 text-white mb-3 animated slideInDown">Contact</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center text-uppercase">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item"><a href="#">Pages</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Contact</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
           {/*Page Header End */}

            <Booking/>
            {/*Contact Start*/}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title text-center text-primary text-uppercase">Contact Us</h6>
                        <h1 className="mb-5"><span className="text-primary text-uppercase">Contact</span> For Any Query
                        </h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-12">
                            <div className="row gy-4">
                                <div className="col-md-4">
                                    <h6 className="section-title text-start text-primary text-uppercase">E mail</h6>
                                    <p><i className="fa fa-envelope-open text-primary me-2"></i>dumindawalgamage@gmail.com</p>
                                    <p><i className="fa fa-envelope-open text-primary me-2"></i>dkzureinkehrinfo@gmail.com</p>
                                </div>
                                <div className="col-md-4">
                                    <h6 className="section-title text-start text-primary text-uppercase">Address</h6>
                                    <p><i className="fa fa-envelope-open text-primary me-2"></i>Welhengoda Road , Wlhengoda, Ahangama</p>
                                </div>
                                <div className="col-md-4">
                                    <h6 className="section-title text-start text-primary text-uppercase">Phone</h6>
                                    <p><i className="fa fa-envelope-open text-primary me-2"></i>+94 704 998 667</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
                            <iframe className="position-relative rounded w-100 h-100"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3968.0996410670277!2d80.35073977447772!3d5.981016229230673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae1130bb4de0085%3A0xb261c2b86c9cf79f!2sDK%20Zur%20Einkehr%20hotel!5e0!3m2!1sen!2slk!4v1720627419703!5m2!1sen!2slk"
                                    frameborder="0" style={{minHeight: '350px', border: 0}} allowfullscreen=""
                                    aria-hidden="false"
                                    tabindex="0"></iframe>
                        </div>
                        <div className="col-md-6">
                            <div className="wow fadeInUp" data-wow-delay="0.2s">
                                <form>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="name"
                                                       placeholder="Your Name"/>
                                                <label for="name">Your Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" id="email"
                                                       placeholder="Your Email"/>
                                                <label for="email">Your Email</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="subject"
                                                       placeholder="Subject"/>
                                                <label for="subject">Subject</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea className="form-control" placeholder="Leave a message here"
                                                          id="message" style={{height: '150px'}}></textarea>
                                                <label for="message">Message</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary w-100 py-3" type="submit">Send Message
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Contact End*/}


            {/* Newsletter Start*/}

            <Newsletter/>
            {/* Newsletter Start*/}
        </>
    )
}
