import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { Booking } from '../component/Booking';
import { Newsletter } from '../component/Newsletter';

export const BookRoom = () => {
    const location = useLocation();
    const selectedRoom = location.state ? location.state.selectedPackage : '';

    const bookingDetails = location.state || {}; // Get the passed state

    const { checkIn, checkOut, adults, children } = bookingDetails;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        startDate: checkIn,
        endDate: checkOut,
        adults: adults,
        children: children,
        selectedRoom: selectedRoom,
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            'service_xn1emjj',       // Replace with your EmailJS service ID
            'template_sl9hilw',      // Replace with your EmailJS template ID
            formData,
            'i2ohIJnOrox0WdZh3'      // Replace with your EmailJS user ID
        )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Message sent successfully!');
            })
            .catch((err) => {
                console.error('FAILED...', err);
                alert('Failed to send message. Please try again later.');
            });

        setFormData({
            name: '',
            email: '',
            startDate: checkIn,
            endDate: checkOut,
            adults: adults,
            children: children,
            selectedRoom: selectedRoom,
            message: '',
        });
    };


    return (
        <>
            {/*Page Header Start*/}
            <div className="container-fluid page-header mb-5 p-0" style={{ backgroundImage: 'url(/img/Room01.jpg)' }}>
                <div className="container-fluid page-header-inner py-5">
                    <div className="container text-center pb-5">
                        <h1 className="display-3 text-white mb-3 animated slideInDown">Booking</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center text-uppercase">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item"><a href="#">Pages</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Booking</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            {/*Page Header End*/}

            <Booking />

            {/*Booking Start*/}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title text-center text-primary text-uppercase">Room Booking</h6>
                        <h1 className="mb-5">Book A <span className="text-primary text-uppercase">Room</span></h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <div className="row g-3">
                                <div className="col-6 text-end">
                                    <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.1s" src="img/Room01.jpg" style={{ marginTop: '25%' }} />
                                </div>
                                <div className="col-6 text-start">
                                    <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.3s" src="img/Room02.jpg" />
                                </div>
                                <div className="col-6 text-end">
                                    <img className="img-fluid rounded w-50 wow zoomIn" data-wow-delay="0.5s" src="img/Room05.jpg" />
                                </div>
                                <div className="col-6 text-start">
                                    <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.7s" src="img/Room4.jpg" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="wow fadeInUp" data-wow-delay="0.2s">
                                <form onSubmit={handleSubmit}>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    placeholder="Your Name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                                <label htmlFor="name">Your Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    placeholder="Your Email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                <label htmlFor="email">Your Email</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="date" id="date1" data-target-input="nearest">
                                                <input
                                                    type="datetime-local"
                                                    className="form-control"
                                                    placeholder="Check in"
                                                    name="startDate"
                                                    value={formData.startDate}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="date" id="date2" data-target-input="nearest">
                                                <input
                                                    type="datetime-local"
                                                    className="form-control"
                                                    placeholder="Check out"
                                                    name="endDate"
                                                    value={formData.endDate}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Adults"
                                                    min="1"
                                                    max="10"
                                                    id="adults"
                                                    name="adults"
                                                    value={formData.adults}
                                                    onChange={handleChange}
                                                />
                                                <label htmlFor="adults">Select Adults</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Children"
                                                    min="0"
                                                    max="10"
                                                    id="children"
                                                    name="children"
                                                    value={formData.children}
                                                    onChange={handleChange}
                                                />
                                                <label htmlFor="children">Select Children</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <select
                                                    className="form-select"
                                                    id="selectedRoom"
                                                    name="selectedRoom"
                                                    value={formData.selectedRoom}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select a room</option>
                                                    <option value="Deluxe Triple Room">Deluxe Triple Room</option>
                                                    <option value="Deluxe Double Room (2 Adults + 1 Child)">Deluxe Double Room (2 Adults + 1 Child)</option>
                                                    <option value="Deluxe Double Room (2 Adults + 1 Child)">Deluxe Double Room (2 Adults + 1 Child)</option>
                                                </select>
                                                <label htmlFor="selectedRoom">Select A Room</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea
                                                    className="form-control"
                                                    placeholder="Special Request"
                                                    id="message"
                                                    name="message"
                                                    style={{ height: '100px' }}
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                ></textarea>
                                                <label htmlFor="message">Special Request</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary w-100 py-3" type="submit">Book Now</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Booking End*/}

            {/*Newsletter Start*/}
            <Newsletter />
            {/*Newsletter End*/}
        </>
    );
};
