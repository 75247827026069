import {Booking} from "../component/Booking";
import {Newsletter} from "../component/Newsletter";


import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import {Rooms} from "../component/Rooms";
import {Services} from "../component/Services";
import Testimonial from "../component/Testimonial";
import ImageSlider from "../component/ImageSlider";


export const Home = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showMore, setShowMore] = useState(false);

    const handleShowMore = () => {
        setShowMore(!showMore);
    };
    return(

        <>
            <div className="container-fluid p-0 mb-5">
                <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="w-100" src="img/new02.jpg" alt="Image"/>
                            <div
                                className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{maxWidth: '700px'}}>
                                    <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown">Luxury
                                        Living</h6>
                                    <h1 className="display-3 text-white mb-4 animated slideInDown">A Tranquil Place in
                                        the Wild!<br/>
                                        <span style={{fontSize: '20px'}}>This is a place where you can experience tranquility, luxury and sea vibes in one place.</span>
                                    </h1>
                                    <a href="room" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Our
                                        Rooms</a>
                                    <a href="bookroom" className="btn btn-light py-md-3 px-md-5 animated slideInRight">Book A
                                        Room</a>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="w-100" src="img/new01.jpg" alt="Image"/>
                            <div
                                className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{maxWidth: '700px'}}>
                                    <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown">Luxury
                                        Living</h6>
                                    <h1 className="display-3 text-white mb-4 animated slideInDown">A Tranquil Place in
                                        the Wild!<br/>
                                        <span style={{fontSize: '20px'}}>This is a place where you can experience tranquility, luxury and sea vibes in one place.</span>
                                    </h1>
                                    <a href="room" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Our
                                        Rooms</a>
                                    <a href="bookroom" className="btn btn-light py-md-3 px-md-5 animated slideInRight">Book A
                                        Room</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                            data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                            data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            {/*Carousel End*/}

            <Booking/>

            {/* About Start*/}

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h6 className="section-title text-start text-primary text-uppercase">About Us</h6>
                            <h1 className="mb-4">Welcome to <span
                                className="text-primary text-uppercase" >DK Zur Einkehr Hotel </span></h1>
                            <p className="mb-4" style={{textAlign:'justify'}}>Situated in Ahangama in the Galle District, DK Zur Einkehr Hotel is a
                                luxury hotel in Ahangama located in the middle of the jungle and just five minutes away
                                from the beach. This place provides you a calm stay in the wild with scenic views of the
                                sky through the jungle. This is a freshly renovated hotel that gives a glimpse of a
                                combination of modern and old school interiors and a perfect ratio. The jungle like
                                experience is added together with amazing views of sunrise and sunset. The rooms are
                                clean, bright and spacious. Each room has a terrace. We ensure that out friendly hosting
                                will be the best part of your stay at the hotel! </p>
                            {/*<div className="row g-3 pb-4">
                                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
                                    <div className="border rounded p-1">
                                        <div className="border rounded text-center p-4">
                                            <i className="fa fa-hotel fa-2x text-primary mb-2"></i>
                                            <h2 className="mb-1" data-toggle="counter-up">1234</h2>
                                            <p className="mb-0">Rooms</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
                                    <div className="border rounded p-1">
                                        <div className="border rounded text-center p-4">
                                            <i className="fa fa-users-cog fa-2x text-primary mb-2"></i>
                                            <h2 className="mb-1" data-toggle="counter-up">1234</h2>
                                            <p className="mb-0">Staffs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
                                    <div className="border rounded p-1">
                                        <div className="border rounded text-center p-4">
                                            <i className="fa fa-users fa-2x text-primary mb-2"></i>
                                            <h2 className="mb-1" data-toggle="counter-up">1234</h2>
                                            <p className="mb-0">Clients</p>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                            <a className="btn btn-primary py-3 px-5 mt-2" onClick={handleShow}>Explore More</a>
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Explore More</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="image-text-container">
                                        <img className="img-fluid" src="img/Hotel01.jpg" alt="Hotel 1"/>
                                        <p>
                                            Situated in Ahangama in the Galle District region and Kabalana Beach
                                            reachable within less than 1 km, DK Zur Einkehr Hotel features accommodation
                                            with free WiFi, barbecue facilities, a private beach area, and free private
                                            parking. This guest house provides water sports facilities, an outdoor
                                            swimming pool, as well as free bikes. Boasting family rooms, this property
                                            also provides guests with a sun terrace.
                                        </p>
                                    </div>
                                    <div className="image-text-container">
                                        <img className="img-fluid" src="img/hotel2.jpg" alt="Hotel 2"/>
                                        <p>
                                            Some accommodation includes a balcony and a seating area with flat-screen
                                            TV, as well as air conditioning and heating. At the guest house, some units
                                            are fitted with a kettle and fruits.
                                        </p>
                                    </div>
                                    <div className="image-text-container">
                                        <img className="img-fluid" src="img/hotel4.jpg" alt="Hotel 3"/>
                                        <p>
                                            Continental and Full English/Irish breakfast options with warm dishes, local
                                            specialities, and fresh pastries are available each morning at the guest
                                            house. Guests can grab a bite to eat in the in-house family-friendly
                                            restaurant, which specializes in American cuisine and also offers
                                            vegetarian, dairy-free, and gluten-free options.
                                        </p>
                                    </div>
                                    <div className="image-text-container">
                                        <p>
                                            A car rental service is available at DK Zur Einkehr Hotel.
                                        </p>
                                        <p>
                                            <img className="img-fluid" src="img/hotel5.jpg" alt="Hotel 3"/>
                                            Kathaluwa West Beach is 1.6 km from the accommodation, while Galle
                                            International Cricket Stadium is 19 km from the property. The nearest
                                            airport is Koggala Airport, 5 km from DK Zur Einkehr Hotel.
                                        </p>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                        </div>
                        <div className="col-lg-6">
                            <div className="row g-3">
                                <div className="col-6 text-end">
                                    <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.1s"
                                         src="img/about01.jpg" style={{marginTop: '25%'}}/>
                                </div>
                                <div className="col-6 text-start">
                                    <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.3s"
                                         src="img/about03.jpg"/>
                                </div>
                                <div className="col-6 text-end">
                                    <img className="img-fluid rounded w-50 wow zoomIn" data-wow-delay="0.5s"
                                         src="img/aboutNew.jpg"/>
                                </div>
                                <div className="col-6 text-start">
                                    <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.7s"
                                         src="img/about02.jpg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*About End*/}


            {/* Rooms Start*/}

            <Rooms/>
            {/*Rooms End*/}


            {/* Video Start*/}

            <div className="container-xxl py-5 px-0 wow zoomIn" data-wow-delay="0.1s">
                <div className="row g-0">
                    <div className="col-md-6 bg-dark d-flex align-items-center">
                        <div className="p-5">
                            <h6 className="section-title text-start text-white text-uppercase mb-3">Luxury Living</h6>
                            <h1 className="text-white mb-4">Our Rooms</h1>
                            <p className="text-white mb-4">The hotel offers different types of rooms which you can
                                select adjusting to the number of pax and your purpose of stay. </p>
                            <a href="room" className="btn btn-primary py-md-3 px-md-5 me-3">Our Rooms</a>
                            <a href="bookroom" className="btn btn-light py-md-3 px-md-5">Book A Room</a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="video">
                           <ImageSlider/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="videoModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content rounded-0">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Youtube Video</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            16:9 aspect ratio

                            <div className="ratio ratio-16x9">
                                <iframe className="embed-responsive-item" src="" id="video" allowFullScreen
                                        allowscriptaccess="always"
                                        allow="autoplay"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Video Start*/}


            {/* Service Start*/}

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title text-center text-primary text-uppercase">Our Services</h6>
                        <h1 className="mb-5">
                            Explore Our <span className="text-primary text-uppercase">Services</span>
                        </h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item rounded">
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-umbrella-beach fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Beach Access</h5>
                                <p className="text-body mb-0">Only 600m away from the Beach.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="service-item rounded">
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-shower fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Bathroom</h5>
                                <p className="text-body mb-0">We offer private bathrooms for each and every room.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded">
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-street-view fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">View</h5>
                                <p className="text-body mb-0">This place offers a magnificent garden view.</p>
                            </div>
                        </div>

                        {/*<div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded" >
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-campground fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Outdoors</h5>
                                <p className="text-body mb-0">
                                    The place offers outdoor furniture, sun terrace, private beach area, bbq facilities
                                    and a garden.
                                </p>
                            </div>
                        </div>*/}

                        <div className={`col-lg-4 col-md-6 wow fadeInUp ${showMore ? 'd-block' : 'd-none'}`}
                             data-wow-delay="0.6s">
                            <div className="service-item rounded">
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-glass-cheers fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Kitchen</h5>
                                <p className="text-body mb-0">We offer washing machine and refrigerator facilities.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded">
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-restroom fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Room Amenities</h5>
                                <p className="text-body mb-0">We offer a clothes rack in every room.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded">
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-shoe-prints fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Activities </h5>
                                <p className="text-body mb-0">Cycling, activities at the beach, water sports facilities
                                    are the activities available here.</p>
                            </div>
                        </div>

                        <div className={`col-lg-4 col-md-6 wow fadeInUp ${showMore ? 'd-block' : 'd-none'}`}
                             data-wow-delay="0.6s">
                            <div className="service-item rounded">
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-tv fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">TV</h5>
                                <p className="text-body mb-0">A TV is available in every room.</p>
                            </div>
                        </div>

                        <div className={`col-lg-4 col-md-6 wow fadeInUp ${showMore ? 'd-block' : 'd-none'}`}
                             data-wow-delay="0.6s">
                            <div className="service-item rounded">
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-utensils fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Food and Drink</h5>
                                <p className="text-body mb-0">Kid meals, breakfast in the room and a restaurant is
                                    available. </p>
                            </div>
                        </div>

                        <div className={`col-lg-4 col-md-6 wow fadeInUp ${showMore ? 'd-block' : 'd-none'}`}
                             data-wow-delay="0.6s">
                            <div className="service-item rounded">
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-wifi fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Internet </h5>
                                <p className="text-body mb-0">Free Wifi is available in all areas. </p>
                            </div>
                        </div>

                        <div className={`col-lg-4 col-md-6 wow fadeInUp ${showMore ? 'd-block' : 'd-none'}`}
                             data-wow-delay="0.6s">
                            <div className="service-item rounded">
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-parking fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Parking</h5>
                                <p className="text-body mb-0">Free on site parking available.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded">
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-car fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Transport</h5>
                                <p className="text-body mb-0">Car hire, airport shuttle, room service are
                                    available. </p>
                            </div>
                        </div>

                        <div className={`col-lg-4 col-md-6 wow fadeInUp ${showMore ? 'd-block' : 'd-none'}`}
                             data-wow-delay="0.6s">
                            <div className="service-item rounded">
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-water fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Outdoor swimming pool</h5>
                                <p className="text-body mb-0">And outdoor swimming pool is available with stunning
                                    views.</p>
                            </div>
                        </div>

                        <div className={`col-lg-4 col-md-6 wow fadeInUp ${showMore ? 'd-block' : 'd-none'}`}
                             data-wow-delay="0.6s">
                            <div className="service-item rounded">
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-language fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Languages spoken</h5>
                                <p className="text-body mb-0">Czech, German, English, Russian languages are spoken. </p>
                            </div>
                        </div>

                        {/* Add more services here with similar structure */}

                        <div className="text-center mt-4">
                            <button className="btn btn-primary" onClick={handleShowMore}>
                                {showMore ? 'Show Less' : 'Show More'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/*Service End*/}


            {/* Testimonial Start*/}
            {/*<Testimonial/>*/}
            {/*Testimonial End*/}


            {/* Team Start*/}

            {/*Team End*/}


            {/*Newsletter Start*/}

            <Newsletter/>
            {/*Newsletter Start*/}

        </>
    )
}
