import React, { useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Modal from 'react-modal';


const images = [
    { image: process.env.PUBLIC_URL + '/img/about01.jpg' },
    { image: process.env.PUBLIC_URL + '/img/about02.jpg' },
    { image: process.env.PUBLIC_URL + '/img/about03.jpg' },
    { image: process.env.PUBLIC_URL + '/img/about04.jpg' },
    { image: process.env.PUBLIC_URL + '/img/about05.jpg' },
    { image: process.env.PUBLIC_URL + '/img/WhatsApp Image 2024-07-09 at 10.29.26_5f76f5d9.jpg' },
    { image: process.env.PUBLIC_URL + '/img/about06.jpg' },
    { image: process.env.PUBLIC_URL + '/img/about07.jpg' },
    { image: process.env.PUBLIC_URL + '/img/about09.jpg' },
    { image: process.env.PUBLIC_URL + '/img/aboutNew.jpg' },
    { image: process.env.PUBLIC_URL + '/img/Room01.jpg' },
    { image: process.env.PUBLIC_URL + '/img/Room02.jpg' },
    { image: process.env.PUBLIC_URL + '/img/WhatsApp Image 2024-07-09 at 10.29.26_f10a7a5c.jpg' },
    { image: process.env.PUBLIC_URL + '/img/Room4.jpg' },
    { image: process.env.PUBLIC_URL + '/img/WhatsApp Image 2024-07-09 at 10.29.26_3621e806.jpg' },
    { image: process.env.PUBLIC_URL + '/img/Room05.jpg' },
    { image: process.env.PUBLIC_URL + '/img/sliderimage01.jpg' },
    { image: process.env.PUBLIC_URL + '/img/sliderimage02.jpg' },
    { image: process.env.PUBLIC_URL + '/img/sliderimage03.jpg' },
    { image: process.env.PUBLIC_URL + '/img/sliderimage04.jpg' },
    { image: process.env.PUBLIC_URL + '/img/WhatsApp Image 2024-07-09 at 10.28.37_67ab6050.jpg' },
    { image: process.env.PUBLIC_URL + '/img/WhatsApp Image 2024-07-09 at 10.29.26_0baf2a99.jpg' },
    { image: process.env.PUBLIC_URL + '/img/WhatsApp Image 2024-07-09 at 10.29.26_9aaf92d3.jpg' },
    { image: process.env.PUBLIC_URL + '/img/Room03.jpg' },
    { image: process.env.PUBLIC_URL + '/img/WhatsApp Image 2024-07-09 at 10.29.26_78fb134d.jpg' },
    { image: process.env.PUBLIC_URL + '/img/WhatsApp Image 2024-07-09 at 10.29.26_3247c087.jpg' },
    { image: process.env.PUBLIC_URL + '/img/WhatsApp Image 2024-07-09 at 10.27.41_169ec5c5.jpg' },
    { image: process.env.PUBLIC_URL + '/img/WhatsApp Image 2024-07-09 at 10.27.41_825dbe40.jpg' },
    { image: process.env.PUBLIC_URL + '/img/WhatsApp Image 2024-07-09 at 10.27.41_51953e18.jpg' },
    { image: process.env.PUBLIC_URL + '/img/WhatsApp Image 2024-07-09 at 10.27.41_bca2306d.jpg' },
    { image: process.env.PUBLIC_URL + '/img/WhatsApp Image 2024-07-09 at 10.27.41_e6fd76c6.jpg' },
    { image: process.env.PUBLIC_URL + '/img/WhatsApp Image 2024-07-09 at 10.27.41_febd37b3.jpg' },
    { image: process.env.PUBLIC_URL + '/img/WhatsApp Image 2024-07-09 at 10.27.42_1b0cde8b.jpg' },



];

Modal.setAppElement('#root');

const Gallery = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');

    const openModal = (img) => {
        setCurrentImage(img);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setCurrentImage('');
    };

    return (
        <div style={{ marginTop: '30px', marginRight: '10px', marginLeft: '10px', marginBottom:'140px' }}>
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
                <Masonry gutter="10px" className="gallery">
                    {images.map((img, i) => (
                        <div key={i} className="masonry-item">
                            <img
                                src={img.image}
                                style={{ width: '100%', height: 'auto', display: 'block', cursor: 'pointer' }}
                                alt=""
                                onClick={() => openModal(img.image)}
                            />
                        </div>
                    ))}
                </Masonry>
            </ResponsiveMasonry>

            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                className="modal"
                overlayClassName="modal-overlay"
            >
                <button onClick={closeModal} className="close-btn">×</button>
                <img src={currentImage} alt="" className="modal-image"/>
            </Modal>
        </div>
    );
};

export default Gallery;
