import {Header} from "./component/Header";
import {Footer} from "./component/Footer";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {Home} from "./pages/Home";
import {Contact} from "./pages/Contact";
import {BookRoom} from "./pages/BookRoom";
import {Room} from "./pages/Room";
import {Service} from "./pages/Service";
import {About} from "./pages/About";
import Gallery from "./component/Gallery";
function App() {
  return (
      <div className="App">
          <BrowserRouter>
              <Header/>
              <Routes>
                  <Route path="/" element={<Home/>}/>
                  <Route path="/contact" element={<Contact/>}/>
                  <Route path="/bookroom" element={<BookRoom/>}/>
                  <Route path="/room" element={<Room/>}/>
                  <Route path="/service" element={<Service/>}/>
                  <Route path="/about" element={<About/>}/>
                  <Route path="/gallery" element={<Gallery/>}/>
                  {/*<Route path="/about" element={<About/>}/>
                  <Route path='/animal' element={<Animals/>}/>
                  <Route path='/visiting' element={<Visiting/>}/>
                  <Route path='/member' element={<Membership/>}/>
                  <Route path="/service" element={<Service/>}/>
                  <Route path="/contact" element={<Contact/>}/>
                  <Route path="/testimonial" element={<Testimonial/>}/>
                  <Route path="/more" element={<More/>}/>
                  <Route path="/packages" element={<Packages/>}/>

                  <Route path='*' element={<Notfound/>}/>*/}
              </Routes>
              <Footer/>
          </BrowserRouter>
      </div>
  );
}

export default App;
