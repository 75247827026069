import {Booking} from "../component/Booking";
import {Newsletter} from "../component/Newsletter";
import {Button, Modal} from "react-bootstrap";
import React, {useState} from "react";


export const About = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return(

        <>
            {/*Page Header Start*/}
            <div className="container-fluid page-header mb-5 p-0" style={{backgroundImage: 'url(/img/about09.jpg)'}}>
                <div className="container-fluid page-header-inner py-5">
                    <div className="container text-center pb-5">
                        <h1 className="display-3 text-white mb-3 animated slideInDown">About Us</h1>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center text-uppercase">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item"><a href="#">Pages</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">About</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            {/*Page Header End*/}



             {/*Booking Start */}
            <Booking/>
            {/* Booking End*/}


             {/*About Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h6 className="section-title text-start text-primary text-uppercase">About Us</h6>
                            <h1 className="mb-4">Welcome to <span className="text-primary text-uppercase">DK Zur Einkehr Hotel</span>
                            </h1>
                            <p className="mb-4" style={{textAlign: 'justify'}}>Situated in Ahangama in the Galle
                                District, DK Zur Einkehr Hotel is a luxury hotel in Ahangama located in the middle of
                                the jungle and just five minutes away from the beach. This place provides you a calm
                                stay in the wild with scenic views of the sky through the jungle. This is a freshly
                                renovated hotel that gives a glimpse of a combination of modern and old school interiors
                                and a perfect ratio. The jungle like experience is added together with amazing views of
                                sunrise and sunset. The rooms are clean, bright and spacious. Each room has a terrace.
                                We ensure that out friendly hosting will be the best part of your stay at the
                                hotel! </p>
                            {/*<div className="row g-3 pb-4">
                                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
                                    <div className="border rounded p-1">
                                        <div className="border rounded text-center p-4">
                                            <i className="fa fa-hotel fa-2x text-primary mb-2"></i>
                                            <h2 className="mb-1" data-toggle="counter-up">1234</h2>
                                            <p className="mb-0">Rooms</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
                                    <div className="border rounded p-1">
                                        <div className="border rounded text-center p-4">
                                            <i className="fa fa-users-cog fa-2x text-primary mb-2"></i>
                                            <h2 className="mb-1" data-toggle="counter-up">1234</h2>
                                            <p className="mb-0">Staffs</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
                                    <div className="border rounded p-1">
                                        <div className="border rounded text-center p-4">
                                            <i className="fa fa-users fa-2x text-primary mb-2"></i>
                                            <h2 className="mb-1" data-toggle="counter-up">1234</h2>
                                            <p className="mb-0">Clients</p>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                            <a className="btn btn-primary py-3 px-5 mt-2" onClick={handleShow}>Explore More</a>
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Explore More</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="image-text-container">
                                        <img className="img-fluid" src="img/Hotel01.jpg" alt="Hotel 1"/>
                                        <p>
                                            Situated in Ahangama in the Galle District region and Kabalana Beach
                                            reachable within less than 1 km, DK Zur Einkehr Hotel features accommodation
                                            with free WiFi, barbecue facilities, a private beach area, and free private
                                            parking. This guest house provides water sports facilities, an outdoor
                                            swimming pool, as well as free bikes. Boasting family rooms, this property
                                            also provides guests with a sun terrace.
                                        </p>
                                    </div>
                                    <div className="image-text-container">
                                        <img className="img-fluid" src="img/hotel2.jpg" alt="Hotel 2"/>
                                        <p>
                                            Some accommodation includes a balcony and a seating area with flat-screen
                                            TV, as well as air conditioning and heating. At the guest house, some units
                                            are fitted with a kettle and fruits.
                                        </p>
                                    </div>
                                    <div className="image-text-container">
                                        <img className="img-fluid" src="img/hotel4.jpg" alt="Hotel 3"/>
                                        <p>
                                            Continental and Full English/Irish breakfast options with warm dishes, local
                                            specialities, and fresh pastries are available each morning at the guest
                                            house. Guests can grab a bite to eat in the in-house family-friendly
                                            restaurant, which specializes in American cuisine and also offers
                                            vegetarian, dairy-free, and gluten-free options.
                                        </p>
                                    </div>
                                    <div className="image-text-container">
                                        <p>
                                            A car rental service is available at DK Zur Einkehr Hotel.
                                        </p>
                                        <p>
                                            <img className="img-fluid" src="img/hotel5.jpg" alt="Hotel 3"/>
                                            Kathaluwa West Beach is 1.6 km from the accommodation, while Galle
                                            International Cricket Stadium is 19 km from the property. The nearest
                                            airport is Koggala Airport, 5 km from DK Zur Einkehr Hotel.
                                        </p>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        <div className="col-lg-6">
                            <div className="row g-3">
                                <div className="col-6 text-end">
                                    <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.1s"
                                         src="img/about09.jpg" style={{marginTop: '30%', height:'200px'}}/>
                                </div>
                                <div className="col-6 text-start">
                                    <img className="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.3s"
                                         src="img/about08.jpg"/>
                                </div>
                                <div className="col-6 text-end">
                                    <img className="img-fluid rounded w-50 wow zoomIn" data-wow-delay="0.5s"
                                         src="img/about06.jpg"/>
                                </div>
                                <div className="col-6 text-start">
                                    <img className="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.7s"
                                         src="img/about07.jpg"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About End */}


            {/*Team Start*/}

           {/* Team End*/}

            <Newsletter/>

        </>
    )
}
