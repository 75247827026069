import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';

export const Booking = () => {
    const [checkIn, setCheckIn] = useState('');
    const [checkOut, setCheckOut] = useState('');
    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        // Process the booking with the captured values
        const bookingDetails = {
            checkIn,
            checkOut,
            adults,
            children,
        };

        console.log('Booking details:', bookingDetails);

        // Navigate to the next page with the booking details
        navigate('/bookroom', { state: bookingDetails });
    };

    return (
        <>
            <div className="container-fluid booking pb-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container">
                    <div className="bg-white shadow" style={{ padding: '35px' }}>
                        <form onSubmit={handleSubmit}>
                            <div className="row g-2 align-items-end">
                                <div className="col-md-10">
                                    <div className="row g-2">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="date1">Check in</label>
                                                <input
                                                    type="datetime-local"
                                                    className="form-control"
                                                    id="date1"
                                                    value={checkIn}
                                                    onChange={(e) => setCheckIn(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="date2">Check out</label>
                                                <input
                                                    type="datetime-local"
                                                    className="form-control"
                                                    id="date2"
                                                    value={checkOut}
                                                    onChange={(e) => setCheckOut(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="adult">No of Adult</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="adult"
                                                    value={adults}
                                                    min="0"
                                                    max="10"
                                                    onChange={(e) => setAdults(parseInt(e.target.value))}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="child">No of Child</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="child"
                                                    value={children}
                                                    min="0"
                                                    max="10"
                                                    onChange={(e) => setChildren(parseInt(e.target.value))}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 d-flex align-items-end">
                                    <button type="submit" className="btn btn-primary w-100">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
