import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {scroller} from 'react-scroll';

export const Rooms = () => {

    const [showMore1, setShowMore1] = useState(false);
    const [showMore2, setShowMore2] = useState(false);
    const [showMore3, setShowMore3] = useState(false);

    const handleViewMore1 = () => {
        setShowMore1(!showMore1);
    };
    const handleViewMore2 = () => {
        setShowMore2(!showMore2);
    };
    const handleViewMore3 = () => {
        setShowMore3(!showMore3);
    };

    const [selectedPackage, setSelectedPackage] = useState(null);

    const navigate = useNavigate();

    const handleBookNowClick = (packageName) => {
        setSelectedPackage(packageName);
        navigate('/bookroom', {state: {selectedPackage: packageName}}); // Pass selected package name as state
        setTimeout(() => {
            scroller.scrollTo('cform', {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        }, 100); // Adjust the timeout as needed
    };
    return(

        <>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title text-center text-primary text-uppercase">Our Rooms</h6>
                        <h1 className="mb-5">Explore Our <span className="text-primary text-uppercase">Rooms</span></h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="room-item shadow rounded overflow-hidden h-100 d-flex flex-column">
                                <div className="position-relative">
                                    <img className="img-fluid" src="img/tripleRoom.jpg" alt=""/>
                                </div>
                                <div className="p-4 mt-2 d-flex flex-column flex-grow-1">
                                    <div className="d-flex justify-content-between mb-3">
                                        <h5 className="mb-0">Deluxe Triple Room</h5>
                                        <div className="ps-2">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-3">
                                        <small className="border-end me-3 pe-3"><i
                                            className="fa fa-bed text-primary me-2"></i>2 Bed</small>
                                        <small className="border-end me-3 pe-3"><i
                                            className="fa fa-user text-primary me-2"></i>3 Adults</small>
                                        <small><i className="fa fa-wifi text-primary me-2"></i>Wifi</small>
                                    </div>
                                    <p className="text-body mb-3">Amenities and Facilities 279m2, TV, Air Conditioning,
                                        Toaster, Balcony, Garden View, Pool View, Free Wifi</p>
                                    {showMore1 && (
                                        <div className="additional-content">
                                            <p className="text-body mb-3">This triple room features a pool with a view. The spacious triple room offers air conditioning, a washing machine, a balcony with garden views as well as a private bathroom boasting a shower. The unit has 2 beds.</p>
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-between mt-auto">
                                        <button className="btn btn-sm  rounded py-2 px-4"
                                                style={{backgroundColor:'#FEA116', color:'white'}}
                                                onClick={handleViewMore1}>
                                            {showMore1 ? 'View Less' : 'View More'}
                                        </button>
                                        <a className="btn btn-sm btn-dark rounded py-2 px-4" href="bookroom"
                                           onClick={() => handleBookNowClick('Deluxe Triple Room')}>Book Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*-----------------------------------------------*/}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="room-item shadow rounded overflow-hidden h-100 d-flex flex-column">
                                <div className="position-relative">
                                <img className="img-fluid" src="img/double.jpg" alt=""/>
                                </div>
                                <div className="p-4 mt-2 d-flex flex-column flex-grow-1">
                                    <div className="d-flex justify-content-between mb-3">
                                        <h5 className="mb-0">Deluxe Double Room (2 Adults + 1 Child)</h5>
                                        <div className="ps-2">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-3">
                                        <small className="border-end me-3 pe-3"><i
                                            className="fa fa-bed text-primary me-2"></i>1 Bed</small>
                                        <small className="border-end me-3 pe-3"><i
                                            className="fa fa-user text-primary me-2"></i>2 Adults</small>
                                        <small><i className="fa fa-wifi text-primary me-2"></i>Wifi</small>
                                    </div>
                                    <p className="text-body mb-3">Amenities and Facilities 279 m2, Balcony, Garden View,
                                        Pool View, Pool with a view, Private Bathroom, Cleaning products, Refrigerator,
                                        Free Wifi</p>
                                    {showMore2 && (
                                        <div className="additional-content">
                                            <p className="text-body mb-3">This double room's special feature is the pool with a view. The spacious double room provides air conditioning, a washing machine, a balcony with garden views as well as a private bathroom featuring a shower. The unit offers 1 bed.</p>
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-between mt-auto">
                                        <button className="btn btn-sm  rounded py-2 px-4"
                                                style={{backgroundColor:'#FEA116', color:'white'}}
                                                onClick={handleViewMore2}>
                                            {showMore2 ? 'View Less' : 'View More'}
                                        </button>
                                        <a className="btn btn-sm btn-dark rounded py-2 px-4" href="bookroom"
                                           onClick={() => handleBookNowClick('Deluxe Double Room (2 Adults + 1 Child)')}>Book Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*--------------------------------*/}
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="room-item shadow rounded overflow-hidden h-100 d-flex flex-column">
                                <div className="position-relative">
                                    <img className="img-fluid" src="img/single.jpg" alt=""/>
                                </div>
                                <div className="p-4 mt-2 d-flex flex-column flex-grow-1">
                                    <div className="d-flex justify-content-between mb-3">
                                        <h5 className="mb-0">Deluxe Single Room (1 Adult + 1 Child)</h5>
                                        <div className="ps-2">
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                            <small className="fa fa-star text-primary"></small>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-3">
                                        <small className="border-end me-3 pe-3"><i
                                            className="fa fa-bed text-primary me-2"></i>1 or 2 Beds</small>
                                        <small className="border-end me-3 pe-3"><i
                                            className="fa fa-user text-primary me-2"></i>1 Adult</small>
                                        <small><i className="fa fa-wifi text-primary me-2"></i>Wifi</small>
                                    </div>
                                    <p className="text-body mb-3">Amenities and Facilities
                                        279 m2, Garden View, Pool with a view, Private bathroom, Refrigerator, Washing Machine, Free Wifi
                                    </p>
                                    {showMore3 && (
                                        <div className="additional-content">
                                            <p className="text-body mb-3">This single room's special feature is the pool with a view. The spacious single room provides air conditioning, a washing machine, a balcony with garden views as well as a private bathroom featuring a shower. The unit offers 1 bed.</p>
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-between mt-auto">
                                        <button className="btn btn-sm  rounded py-2 px-4"
                                                style={{backgroundColor: '#FEA116', color: 'white'}}
                                                onClick={handleViewMore3}>
                                            {showMore3 ? 'View Less' : 'View More'}
                                        </button>
                                        <a className="btn btn-sm btn-dark rounded py-2 px-4" href="bookroom"
                                           onClick={() => handleBookNowClick('Deluxe Double Room (2 Adults + 1 Child)')}>Book Now</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}
