export const Services = () => {

    return(

        <>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title text-center text-primary text-uppercase">Our Services</h6>
                        <h1 className="mb-5">Explore Our <span className="text-primary text-uppercase">Services</span>
                        </h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="service-item rounded" >
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-umbrella-beach fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Beach Access </h5>
                                <p className="text-body mb-0">Only 600m away from the Beach. </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                            <div className="service-item rounded" >
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-shower fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Bathroom </h5>
                                <p className="text-body mb-0">We offer private bathrooms for each and every room.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="service-item rounded" >
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-street-view fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">View </h5>
                                <p className="text-body mb-0">This place offers a magnificent garden view.</p>
                            </div>
                        </div>

                        {/*<div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                            <div className="service-item rounded" >
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-campground fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Outdoors</h5>
                                <p className="text-body mb-0">The place offers outdoor furniture, sun terrace, private
                                    beach area, bbq facilities and a garden. </p>
                            </div>
                        </div>*/}

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="service-item rounded" >
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-glass-cheers fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Kitchen</h5>
                                <p className="text-body mb-0">We offer washing machine and refrigerator facilities.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item rounded" >
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-restroom fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Room Amenities</h5>
                                <p className="text-body mb-0">We offer a cloths rack in every room.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item rounded" >
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-shoe-prints fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Activities </h5>
                                <p className="text-body mb-0">Cycling, activities at the beach, water sports facilities are the activities available here.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item rounded" >
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-tv fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">TV</h5>
                                <p className="text-body mb-0">A TV is available in every room.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item rounded" >
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-utensils fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Food and Drink</h5>
                                <p className="text-body mb-0">Kid meals, breakfast in the room and a restaurant is available. </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item rounded" >
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-wifi fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Internet </h5>
                                <p className="text-body mb-0">Free Wifi is available in all areas. </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item rounded" >
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-parking fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Parking</h5>
                                <p className="text-body mb-0">Free on site parking available.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item rounded" >
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-car fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Transport</h5>
                                <p className="text-body mb-0">Car hire, airport shuttle, room service are available. </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item rounded" >
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-water fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Outdoor swimming pool</h5>
                                <p className="text-body mb-0">And outdoor swimming pool is available with stunning views.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="service-item rounded" >
                                <div className="service-icon bg-transparent border rounded p-1">
                                    <div
                                        className="w-100 h-100 border rounded d-flex align-items-center justify-content-center">
                                        <i className="fa fa-language fa-2x text-primary"></i>
                                    </div>
                                </div>
                                <h5 className="mb-3">Languages spoken</h5>
                                <p className="text-body mb-0">Czech, German, English, Russian languages are spoken. </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
